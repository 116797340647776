// categories for streaming

export const categories = [
  "featured",
  "movies",
  "coming_soon",
  "entertainment",
  "news_or_opinion",
  "reality",
];
