import React from "react";

export default function FeaturedIcon({ color }) {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 5.125C9.35651 5.125 11.137 5.8625 12.4497 7.17525C13.7625 8.488 14.5 10.2685 14.5 12.125C14.5 13.9815 13.7625 15.762 12.4497 17.0747C11.137 18.3875 9.35651 19.125 7.5 19.125C5.64348 19.125 3.86301 18.3875 2.55025 17.0747C1.2375 15.762 0.5 13.9815 0.5 12.125C0.5 10.2685 1.2375 8.488 2.55025 7.17525C3.86301 5.8625 5.64348 5.125 7.5 5.125ZM7.5 8.1875L6.34237 10.5325L3.755 10.9087L5.6275 12.7331L5.18562 15.3109L7.5 14.0937L9.81437 15.31L9.3725 12.7331L11.245 10.9079L8.65762 10.5316L7.5 8.1875ZM8.375 0.749123L12.75 0.749998V3.375L11.5574 4.37075C10.5677 3.85151 9.48718 3.52798 8.375 3.41787V0.749998V0.749123ZM6.625 0.749123V3.41787C5.51318 3.52781 4.43297 3.85104 3.4435 4.36987L2.25 3.375V0.749998L6.625 0.749123Z"
        fill={color}
      />
    </svg>
  );
}
