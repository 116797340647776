import React from "react";

export default function TvIcon({ color }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.87503 11.375V12.625H11.375V13.875H5.12503V12.625H7.62503V11.375H2.62003C2.53795 11.3745 2.45679 11.3578 2.38117 11.3259C2.30556 11.294 2.23699 11.2475 2.1794 11.189C2.1218 11.1305 2.07631 11.0613 2.04553 10.9852C2.01475 10.9091 1.99928 10.8277 2.00003 10.7456V2.62939C2.00003 2.28189 2.2844 2.00002 2.62003 2.00002H13.88C14.2225 2.00002 14.5 2.28064 14.5 2.62939V10.7456C14.5 11.0931 14.2157 11.375 13.88 11.375H8.87503Z"
        fill={color}
      />
    </svg>
  );
}
