import React from "react";

export default function NewsIcon({ color }) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.65 0H12.35C12.5224 0 12.6877 0.0684819 12.8096 0.190381C12.9315 0.312279 13 0.477609 13 0.65V11.05C13 11.2224 12.9315 11.3877 12.8096 11.5096C12.6877 11.6315 12.5224 11.7 12.35 11.7H0.65C0.477609 11.7 0.312279 11.6315 0.190381 11.5096C0.0684819 11.3877 0 11.2224 0 11.05V0.65C0 0.477609 0.0684819 0.312279 0.190381 0.190381C0.312279 0.0684819 0.477609 0 0.65 0ZM3.2825 2.67865C2.85937 3.10072 2.52382 3.60225 2.29515 4.15442C2.06647 4.70659 1.94918 5.2985 1.95 5.89615C1.95 7.1526 2.4596 8.2901 3.2825 9.11365L4.2016 8.19455C3.89929 7.89296 3.65959 7.53458 3.49627 7.14003C3.33296 6.74547 3.24927 6.32252 3.25 5.8955C3.25 4.9985 3.614 4.186 4.2016 3.59775L3.2825 2.678V2.67865ZM9.7175 2.67865L8.7984 3.5984C9.10048 3.89975 9.34005 4.25782 9.50335 4.65202C9.66665 5.04623 9.75048 5.46881 9.75 5.8955C9.75 6.7925 9.386 7.605 8.7984 8.1939L9.7175 9.113C10.1406 8.69093 10.4762 8.1894 10.7049 7.63723C10.9335 7.08506 11.0508 6.49315 11.05 5.8955C11.0508 5.29785 10.9335 4.70594 10.7049 4.15377C10.4762 3.6016 10.1406 3.10007 9.7175 2.678V2.67865ZM6.5 7.84615C7.01717 7.84615 7.51316 7.6407 7.87886 7.27501C8.24455 6.90931 8.45 6.41332 8.45 5.89615C8.45 5.37898 8.24455 4.88299 7.87886 4.51729C7.51316 4.1516 7.01717 3.94615 6.5 3.94615C5.98283 3.94615 5.48684 4.1516 5.12114 4.51729C4.75545 4.88299 4.55 5.37898 4.55 5.89615C4.55 6.41332 4.75545 6.90931 5.12114 7.27501C5.48684 7.6407 5.98283 7.84615 6.5 7.84615ZM6.5 6.54615C6.32761 6.54615 6.16228 6.47767 6.04038 6.35577C5.91848 6.23387 5.85 6.06854 5.85 5.89615C5.85 5.72376 5.91848 5.55843 6.04038 5.43653C6.16228 5.31463 6.32761 5.24615 6.5 5.24615C6.67239 5.24615 6.83772 5.31463 6.95962 5.43653C7.08152 5.55843 7.15 5.72376 7.15 5.89615C7.15 6.06854 7.08152 6.23387 6.95962 6.35577C6.83772 6.47767 6.67239 6.54615 6.5 6.54615Z"
        fill={color}
      />
    </svg>
  );
}
