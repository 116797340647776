import React from "react";

export default function RealityIcon({ color }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7 5.019C7.27976 4.87042 6.82314 4.85941 6.39623 4.98757C5.96932 5.11572 5.59425 5.37639 5.32532 5.73184C5.05638 6.0873 4.90752 6.51911 4.90028 6.96479C4.89304 7.41046 5.02779 7.84688 5.28504 8.21089C5.54229 8.57489 5.90869 8.8476 6.33121 8.98956C6.75374 9.13151 7.21047 9.13534 7.63531 9.00049C8.06016 8.86564 8.43108 8.59911 8.6944 8.23947C8.95771 7.87983 9.09977 7.44573 9.1 7V0.3206C11.9399 1.2124 14 3.8654 14 7C14 10.8661 10.8661 14 7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C7.2359 0 7.4697 0.0119 7.7 0.035V5.019Z"
        fill={color}
      />
    </svg>
  );
}
