import React from "react";

export default function EntertainmentIcon({ color }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0484 2.37H11.4048C11.7336 2.37 12 2.637 12 2.97V11.37C12 11.7012 11.727 11.97 11.4048 11.97H0.59521C0.516687 11.9698 0.438968 11.9542 0.366513 11.9239C0.294057 11.8936 0.228292 11.8494 0.172991 11.7936C0.11769 11.7379 0.0739423 11.6717 0.0442578 11.599C0.0145734 11.5264 -0.000463266 11.4485 1.08733e-05 11.37V2.97C1.08733e-05 2.6388 0.273011 2.37 0.59521 2.37H3.95161L2.43001 0.848399L3.27841 0L5.64841 2.37H6.3516L8.7216 0L9.57 0.848399L8.0484 2.37Z"
        fill={color}
      />
    </svg>
  );
}
